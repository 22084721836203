import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header">
        <h1 className="display-3 text-uppercase text-white mb-3">
          Hazardous Goods
        </h1>
        <div className="d-inline-flex text-white">
          <h6 className="text-uppercase m-0">
            <Link to="/Home">Home</Link>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase text-white m-0">Hazardous Goods</h6>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* Hazardous Goods Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Hazardous Goods.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Hazardous Goods</h1>
              <p>
                {companyname} stands as a specialized expert in the intricate
                realm of global hazardous commodity transportation. Our
                distinguished reputation as a reputable transport company
                revolves around our exceptional proficiency in the cross-border
                delivery of hazardous products, encompassing a wide spectrum of
                dangerous goods classifications. Our extensive experience spans
                all nine categories of hazardous items, encompassing explosives,
                gases, flammable liquids, flammable solids, oxidizing chemicals,
                poisonous and infectious materials, radioactive substances,
                corrosive materials, and various other dangerous products.
                <p>
                  Our hazardous cargo expertise extends to an array of
                  commodities, including but not limited to acids, chemicals,
                  fertilizers, motors, batteries, radioactive isotopes,
                  explosives, biological specimens and cultures, pathological
                  samples, and dry ice. Our clientele includes diverse
                  institutions such as universities, non-governmental
                  organizations (NGOs), and government entities, attesting to
                  our comprehensive capabilities in handling hazardous cargo
                  with the utmost diligence and compliance.
                </p>
                At {companyname}, our commitment to safety, regulatory
                compliance, and efficient handling remains unwavering. We take
                pride in being the trusted partner for organizations seeking
                adept solutions for the transportation of hazardous materials,
                ensuring the protection of both people and the environment while
                facilitating the seamless movement of these critical
                commodities.
              </p>
            </div>
            {/* Hazardous Goods End */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Ocean">
                  <i className="fa fa-angle-right me-2" />
                  Ocean Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Air">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Ground">
                  <i className="fa fa-angle-right me-2" />
                  Ground Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Project">
                  <i className="fa fa-angle-right me-2" />
                  Project Cargo
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Value Added Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Break">
                  <i className="fa fa-angle-right me-2" />
                  Break Bulk
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Hazardous">
                  <i className="fa fa-angle-right me-2" />
                  Hazardous Goods
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Cargo">
                  <i className="fa fa-angle-right me-2" />
                  Cargo Insurance
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Custom">
                  <i className="fa fa-angle-right me-2" />
                  Custom Clearance
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Trading Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Fashion
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Food
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Furniture
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Oil and Gas
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Retail
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
