import { Outlet, Link } from "react-router-dom";
import Getquote from "../pages/Getquote";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  
  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };


  return (
    <>
      {/* Topbar Start */}
      <div className="container-fluid px-5 d-none d-lg-block bg-dark">
        <div className="row gx-5">
          <div className="col-lg-4 text-center py-3">
            <div className="d-inline-flex align-items-center">
              <i className="bi bi-geo-alt fs-1 text-primary me-3" />
              <div className="text-start">
                <h6 className="text-uppercase fw-bold text-white">Our Office</h6>
                <span>Kharghar, Navi Mumbai</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center border-start border-end py-3">
            <div className="d-inline-flex align-items-center">
              <i className="bi bi-envelope-open fs-1 text-primary me-3" />
              <div className="text-start">
                <h6 className="text-uppercase fw-bold text-white">Email Us</h6>
                <span>{companyemail}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center py-3">
            <div className="d-inline-flex align-items-center">
              <i className="bi bi-phone-vibrate fs-1 text-primary me-3" />
              <div className="text-start">
                <h6 className="text-uppercase fw-bold text-white">Call Us</h6>
                <span>{companynumber}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}
      {/* Navbar Start */}
      <div className="container-fluid sticky-top bg-white  bg-light-radial shadow-sm px-5 pe-lg-0">
        <nav className="navbar navbar-expand-lg bg-white bg-light-radial navbar-dark py-3 py-lg-0">
          <Link to="index.html" className="navbar-brand">
           <img src="img/logo.png" style={{height:"90px"}}/>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"> <i className="fa fa-2x fa-bars text-dark"></i> </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/Home" className="nav-item nav-link active">
                Home
              </Link>
              <Link to="/About" className="nav-item nav-link">
                Company
              </Link>
              
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Services
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="/Ocean" className="dropdown-item">
                    Ocean Freight
                  </Link>
                  <Link to="/Air" className="dropdown-item">
                    Air Freight
                  </Link>
                  <Link to="/Ground" className="dropdown-item">
                    Ground Freight
                  </Link>
                  <Link to="/Project" className="dropdown-item">
                    Project Cargo
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Value Added
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="/Break" className="dropdown-item">
                  Break Bulk
                  </Link>
                  <Link to="/Hazardous" className="dropdown-item">
                  Hazardous Goods
                  </Link>
                  <Link to="/Cargo" className="dropdown-item">
                  Cargo Insurance
                  </Link>
                  <Link to="/Custom" className="dropdown-item">
                  Custom Clearance
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Trading Services
                </Link>
                <div className="dropdown-menu m-0">
                <Link to="/Trading" className="dropdown-item">
                Fashion
                  </Link> <Link to="/Trading" className="dropdown-item">
                  Food
                  </Link>
                  <Link to="/Trading" className="dropdown-item">
                  Furniture
                  </Link>
                  <Link to="/Trading" className="dropdown-item">
                  Oil and Gas
                  </Link>
                  <Link to="/Trading" className="dropdown-item">
                  Retail
                  </Link>
                </div>
              </div>
              <Link to="/Contact" className="nav-item nav-link">
                Contact
              </Link>
              <Link
                to="/Getquote"
                className="nav-item nav-link bg-primary text-white px-5 ms-3 d-none d-lg-block"
              >
                Get Quote <i className="bi bi-arrow-right" />
              </Link>
            </div>
          </div>
        </nav>
      </div>
      {/* Navbar End */}
    </>
  );
};

export default Header;
