import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/* Carousel Start */}
      <div className="container-fluid p-0 w3-banner jarallax">
        <video autoPlay loop muted>
          <source src="img/video.mp4"></source>
        </video>
      </div>
      {/* Carousel End */}
      {/* About Start */}
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-7">
            <h1 className="display-5 text-uppercase mb-4">
              Welcome to <span className="text-primary">{companyname}</span> 
            </h1>
            <h4 className="text-uppercase mb-3 text-body">
            Shipment Solutions That Shine..
            </h4>
            <p>
              We provide efficient and cost-effective shipping services.{" "}
              {companyname} specializes in transporting perishable goods and
              maintaining the temperature control of food, wine, and spirits
              during transit. Our unique concept within the global perishables
              network can help your company save money on freight forwarding
              services and enhance supply chain competitiveness. We are
              recognized as experts in international food and beverage freight
              forwarding and storage, offering a wide range of integrated
              shipping and forwarding services. Our dedicated team works hard to
              deliver high-quality shipping services tailored to each customer's
              unique needs and expectations, supported by our global agency
              network. Additionally, we utilize an advanced computerized
              operating system to optimize our services
            </p>
           
           
          </div>
          <div className="col-lg-5 pb-5" style={{ minHeight: "400px" }}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/about.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Services Start */}
      <div className="container-fluid bg-light py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
          <h1 className="display-5 text-uppercase mb-4">
            We Provide <span className="text-primary">The Best</span> Freight
            Forwarding Services
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Ocean Freight.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Ocean Freight</h4>
                <p>
                  {companyname} is dedicated to delivering unparalleled
                  flexibility in sea freight shipments, setting the standard
                  for..
                </p>
                <Link className="btn text-primary" to="/Ocean">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Air Freight</h4>
                <p>
                  {companyname} stands as your trusted partner when it comes to
                  time-critical air freight services, ensuring prompt
                  deliveries..
                </p>
                <Link className="btn text-primary" to="/Air">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Ground Freight.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Ground Freight</h4>
                <p>
                  The demand for transportation services is a dynamic and
                  region-specific aspect that varies across industries...
                </p>
                <Link className="btn text-primary" to="/Ground">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Project Cargo.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Project Cargo</h4>
                <p>
                  When it comes to overseeing the logistics of your industrial
                  ventures, be it the construction of a manufacturing plant...
                </p>
                <Link className="btn text-primary" to="/Project">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}
      {/* Appointment Start */}
      <div className="container-fluid py-6 px-5">
        <div className="row gx-5">
          <div className="col-lg-4 mb-5 mb-lg-0">
            <div className="mb-4">
              <h1 className="display-5 text-uppercase mb-4">
                Contact Us <span className="text-primary">Today</span>
              </h1>
            </div>
            <p className="mb-5">
            Are you ready to take your shipping and logistics needs to the next level? We invite you to fill out our freight forwarding contact form today to experience a seamless and efficient shipping process like never before. Our team of experts is dedicated to providing you with tailored solutions that match your unique requirements. By filling out the form, you're taking the first step towards ensuring your cargo reaches its destination on time, every time.


            </p>
            <Link className="btn btn-primary py-3 px-5" to="/Getquote">
              Get A Quote
            </Link>
          </div>
          <div className="col-lg-8">
            <div className="bg-light text-center p-5">
            <form
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        className="form-control p-4 "
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        className="form-control p-4 "
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        className="form-control p-4 "
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>{" "}
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        className="form-control p-4 "
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <textarea
                        className="form-control p-4 "
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary py-3 px-5  "
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h5 style={{ color: "red" }}>{result}</h5>
            </div>
          </div>
        </div>
      </div>
      {/* Appointment End */}
      {/* Portfolio Start */}
      <div className="container-fluid bg-light py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
          <h1 className="display-5 text-uppercase mb-4">
            Explore Our <span className="text-primary">Trading</span> Services
          </h1>
        </div>
        <div className="row gx-5">
          <div className="col-12 text-center"></div>
        </div>
        <div className="row g-5 portfolio-container">
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img className="img-fluid w-100" src="img/Fashion.jpg" alt="" />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Fashion</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img className="img-fluid w-100" src="img/Food.jpg" alt="" />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Food</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img className="img-fluid w-100" src="img/Furniture.jpg" alt="" />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Furniture</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-2 portfolio-item first"></div>
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img
                className="img-fluid w-100"
                src="img/Oil and Gas.jpg"
                alt=""
              />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Oil and Gas</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img className="img-fluid w-100" src="img/Retail.jpg" alt="" />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Retail</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-2 portfolio-item first"></div>
        </div>
      </div>
      {/* Portfolio End */}

      {/* Blog Start */}
      <div className="container-fluid py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
          <h1 className="display-5 text-uppercase mb-4">
            Some Of Our <span className="text-primary">Value Added</span>{" "}
            Services
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <div className="bg-light">
              <img className="img-fluid" src="img/Break Bulk.jpg" alt="" />
              <div className="p-4">
                <div className="d-flex justify-content-between mb-4"></div>
                <h4 className="text-uppercase mb-3">Break Bulk</h4>
                <Link className="text-uppercase fw-bold" to="/Break">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="bg-light">
              <img className="img-fluid" src="img/Hazardous Goods.jpg" alt="" />
              <div className="p-4">
                <div className="d-flex justify-content-between mb-4"></div>
                <h4 className="text-uppercase mb-3">Hazardous Goods</h4>
                <Link className="text-uppercase fw-bold" to="/Hazardous">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="bg-light">
              <img className="img-fluid" src="img/Cargo Insurance.jpg" alt="" />
              <div className="p-4">
                <div className="d-flex justify-content-between mb-4"></div>
                <h4 className="text-uppercase mb-3">Cargo Insurance</h4>
                <Link className="text-uppercase fw-bold" to="/Cargo">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="bg-light">
              <img
                className="img-fluid"
                src="img/Custom Clearance.jpg"
                alt=""
              />
              <div className="p-4">
                <div className="d-flex justify-content-between mb-4"></div>
                <h4 className="text-uppercase mb-3">Custom Clearance</h4>
                <Link className="text-uppercase fw-bold" to="/Custom">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog End */}

      <Footer />
    </>
  );
}
