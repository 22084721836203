import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header">
        <h1 className="display-3 text-uppercase text-white mb-3">
          Project Cargo
        </h1>
        <div className="d-inline-flex text-white">
          <h6 className="text-uppercase m-0">
            <Link to="/Home">Home</Link>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase text-white m-0">Project Cargo</h6>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* Project Cargo Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Project Cargo.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Project Cargo</h1>
              <p>
                When it comes to overseeing the logistics of your industrial
                ventures, be it the construction of a manufacturing plant, a
                petrochemical facility, a power generation plant, or any other
                ambitious project, the role of a proficient logistics
                transportation provider becomes paramount. This partnership
                entails a comprehensive range of services encompassing the
                management of commodities, cargo handling, and the seamless
                transportation of goods between various points of origin and
                their destined locations. However, it is crucial to emphasize
                that the cornerstone of logistical success hinges on aligning
                delivery timelines with your project's schedule.
                <p>
                  It cannot be overstated that selecting the right transport
                  services provider is pivotal, as an ill-fated choice can lead
                  to project delays that not only affect timelines but also
                  incur substantial financial repercussions. To ensure the
                  successful execution of your endeavors, you can rely on our
                  expertise. We specialize in offering administrative services
                  tailored for procurement and logistics in the realm of
                  large-scale projects. Our commitment extends to meticulous
                  coordination with your procurement team, working
                  collaboratively to guarantee that every aspect of your project
                  adheres to the predetermined schedule.
                </p>
                In essence, partnering with us not only mitigates the risks
                associated with logistical setbacks but also positions your
                projects for success by leveraging our specialized
                administration services and unwavering dedication to punctuality
                and efficiency.
              </p>
            </div>
            {/* Project Cargo End */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Ocean">
                  <i className="fa fa-angle-right me-2" />
                  Ocean Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Air">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Ground">
                  <i className="fa fa-angle-right me-2" />
                  Ground Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Project">
                  <i className="fa fa-angle-right me-2" />
                  Project Cargo
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Value Added Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Break">
                  <i className="fa fa-angle-right me-2" />
                  Break Bulk
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Hazardous">
                  <i className="fa fa-angle-right me-2" />
                  Hazardous Goods
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Cargo">
                  <i className="fa fa-angle-right me-2" />
                  Cargo Insurance
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Custom">
                  <i className="fa fa-angle-right me-2" />
                  Custom Clearance
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Trading Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Fashion
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Food
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Furniture
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Oil and Gas
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Retail
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
