import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header">
        <h1 className="display-3 text-uppercase text-white mb-3">
          Air Freight
        </h1>
        <div className="d-inline-flex text-white">
          <h6 className="text-uppercase m-0">
            <Link to="/Home">Home</Link>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase text-white m-0">Air Freight</h6>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* Air Freight Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Air Freight.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Air Freight</h1>
              <p>
                {companyname} stands as your trusted partner when it comes to
                time-critical air freight services, ensuring prompt deliveries
                from the airport directly to consignees or from shippers to the
                airport. With an unwavering commitment to on-time deliveries, we
                offer competitive pricing and expedited shipping timeframes to
                meet the demands of your time-sensitive cargo. Whether your
                shipments consist of standard-sized items, oversized cargo,
                perishable goods, hanging garments, or require specialized
                handling, we are fully equipped to cater to your diverse
                requirements.
                <p>
                  Our strong partnerships with leading airlines provide our
                  customers with access to a wide spectrum of carriers, flexible
                  routing options, and ample cargo space. {companyname} is your
                  go-to solution for scheduled, deferred, and expedited
                  consolidation services. From the initiation to the completion
                  of your shipments, our experienced team offers meticulous and
                  comprehensive support with documentation, ensuring a smooth
                  and hassle-free shipping experience. Our overarching objective
                  is to earn your trust as the preferred choice for all your
                  shipping-related needs.
                </p>
              </p>
            </div>
            {/* Air Freight End */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Ocean">
                  <i className="fa fa-angle-right me-2" />
                  Ocean Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Air">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Ground">
                  <i className="fa fa-angle-right me-2" />
                  Ground Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Project">
                  <i className="fa fa-angle-right me-2" />
                  Project Cargo
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Value Added Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Break">
                  <i className="fa fa-angle-right me-2" />
                  Break Bulk
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Hazardous">
                  <i className="fa fa-angle-right me-2" />
                  Hazardous Goods
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Cargo">
                  <i className="fa fa-angle-right me-2" />
                  Cargo Insurance
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Custom">
                  <i className="fa fa-angle-right me-2" />
                  Custom Clearance
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Trading Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Fashion
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Food
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Furniture
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Oil and Gas
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Retail
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
