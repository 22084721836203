import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header">
        <h1 className="display-3 text-uppercase text-white mb-3">
        About Us
        </h1>
        <div className="d-inline-flex text-white">
          <h6 className="text-uppercase m-0">
            <Link to="/Home">Home</Link>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase text-white m-0">About Us</h6>
        </div>
      </div>
      {/* About Start */}
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-7">
            <h1 className="display-5 text-uppercase mb-4">
              Welcome to <span className="text-primary">{companyname}</span> 
            </h1>
            <h4 className="text-uppercase mb-3 text-body">
            Shipment Solutions That Shine..
            </h4>
            <p>
              We provide efficient and cost-effective shipping services.{" "}
              {companyname} specializes in transporting perishable goods and
              maintaining the temperature control of food, wine, and spirits
              during transit. Our unique concept within the global perishables
              network can help your company save money on freight forwarding
              services and enhance supply chain competitiveness. We are
              recognized as experts in international food and beverage freight
              forwarding and storage, offering a wide range of integrated
              shipping and forwarding services. Our dedicated team works hard to
              deliver high-quality shipping services tailored to each customer's
              unique needs and expectations, supported by our global agency
              network. Additionally, we utilize an advanced computerized
              operating system to optimize our services
            </p>
           
           
          </div>
          <div className="col-lg-5 pb-5" style={{ minHeight: "400px" }}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/about.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
            {/* Services Start */}
            <div className="container-fluid bg-light py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
          <h1 className="display-5 text-uppercase mb-4">
            We Provide <span className="text-primary">The Best</span> Freight
            Forwarding Services
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Ocean Freight.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Ocean Freight</h4>
                <p>
                  {companyname} is dedicated to delivering unparalleled
                  flexibility in sea freight shipments, setting the standard
                  for..
                </p>
                <Link className="btn text-primary" to="/Ocean">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Air Freight</h4>
                <p>
                  {companyname} stands as your trusted partner when it comes to
                  time-critical air freight services, ensuring prompt
                  deliveries..
                </p>
                <Link className="btn text-primary" to="/Air">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Ground Freight.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Ground Freight</h4>
                <p>
                  The demand for transportation services is a dynamic and
                  region-specific aspect that varies across industries...
                </p>
                <Link className="btn text-primary" to="/Ground">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Project Cargo.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Project Cargo</h4>
                <p>
                  When it comes to overseeing the logistics of your industrial
                  ventures, be it the construction of a manufacturing plant...
                </p>
                <Link className="btn text-primary" to="/Project">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}

      <Footer />
    </>
  );
}
