import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      {/* Footer Start */}
      <div className="footer container-fluid position-relative bg-dark bg-light-radial text-white-50 py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-6 pe-lg-5">
            <Link to="/" className="navbar-brand d-flex align-items-center">
              <img src="img/logo.png" style={{ height: "90px" }} />
              <h3 className="m-0  text-uppercase text-white">{companyname}</h3>
            </Link>
            <p>
              We provide efficient and cost-effective shipping services. Bean
              Tree Enterprises specializes in transporting perishable goods and
              maintaining the temperature control of food, wine, and spirits
              during transit. Our unique concept within the global perishables
              network can help your company save money on freight forwarding
              services and enhance supply chain competitiveness.
            </p>
            <p>
              <i className="fa fa-map-marker-alt me-2" />
              {companyaddress}
            </p>
            <p>
              <i className="fa fa-phone-alt me-2" />
              {companynumber}
            </p>
            <p>
              <i className="fa fa-envelope me-2" />
              {companyemail}
            </p>
          </div>
          <div className="col-lg-6 ps-lg-5">
            <div className="row g-5">
              <div className="col-sm-6">
                <h4 className="text-white text-uppercase mb-4">Quick Links</h4>
                <div className="d-flex flex-column justify-content-start">
                  <Link className="text-white-50 mb-2" to="/">
                    <i className="fa fa-angle-right me-2" />
                    Home
                  </Link>
                  <Link className="text-white-50 mb-2" to="/About">
                    <i className="fa fa-angle-right me-2" />
                    About Us
                  </Link>
                  <Link className="text-white-50 mb-2" to="/Services">
                    <i className="fa fa-angle-right me-2" />
                    Our Services
                  </Link>
                  <Link className="text-white-50 mb-2" to="/Trading">
                    <i className="fa fa-angle-right me-2" />
                    Trading Services
                  </Link>
                  <Link className="text-white-50 mb-2" to="/Contact">
                    <i className="fa fa-angle-right me-2" />
                    Contact Us
                  </Link>
                  <Link className="text-white-50 mb-2" to="/Getquote">
                    <i className="fa fa-angle-right me-2" />
                    Get A Quote
                  </Link>
                  <Link className="text-white-50 mb-2" to="/Terms">
                    <i className="fa fa-angle-right me-2" />
                    Terms
                  </Link>
                  <Link className="text-white-50 mb-2" to="/Privacy">
                    <i className="fa fa-angle-right me-2" />
                    Privacy Policy
                  </Link>
                </div>
              </div>
              <div className="col-sm-6">
                <h4 className="text-white text-uppercase mb-4">
                  Popular Links
                </h4>
                <div className="d-flex flex-column justify-content-start">
                  <Link to="/Ocean" className="text-white-50 mb-2">
                    <i className="fa fa-angle-right me-2" />
                    Ocean Freight
                  </Link>
                  <Link to="/Air" className="text-white-50 mb-2">
                    <i className="fa fa-angle-right me-2" />
                    Air Freight
                  </Link>
                  <Link to="/Ground" className="text-white-50 mb-2">
                    <i className="fa fa-angle-right me-2" />
                    Ground Freight
                  </Link>
                  <Link to="/Project" className="text-white-50 mb-2">
                    <i className="fa fa-angle-right me-2" />
                    Project Cargo
                  </Link>
                  <Link to="/Break" className="text-white-50 mb-2">
                    <i className="fa fa-angle-right me-2" />
                    Break Bulk
                  </Link>
                  <Link to="/Hazardous" className="text-white-50 mb-2">
                    <i className="fa fa-angle-right me-2" />
                    Hazardous Goods
                  </Link>
                  <Link to="/Cargo" className="text-white-50 mb-2">
                    <i className="fa fa-angle-right me-2" />
                    Cargo Insurance
                  </Link>
                  <Link to="/Custom" className="text-white-50 mb-2">
                    <i className="fa fa-angle-right me-2" />
                    Custom Clearance
                  </Link>
                </div>
              </div>
              <div className="col-sm-12">
                <h4 className="text-white text-uppercase mb-4">Newsletter</h4>
                <div className="w-100">
                <form
                  action="/php/thankyou-subscribe.php "
                  method="POST"
                  onSubmit={(event) => handleSubmit(event)}
                 
                >
                  <div className="input-group">
                  <input
                    className="form-control border-light"
                    onChange={(event) => handleSubemailChange(event)}
                    id="subemail"
                    value={subemail}
                    type="email"
                    name="subemail"
                    placeholder="Your email address"
                    style={{ padding: "20px 30px" }}
                    required
                  />
                  <button className="btn btn-primary px-4" type="submit">
                    Subscribe
                  </button>
                  </div>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark bg-light-radial text-white border-top border-primary px-0">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="py-4 px-5 text-center text-md-start">
            <p className="mb-0">
              ©2023{" "}
              <Link className="text-primary" to="/">
                {companyname}
              </Link>
              . All Rights Reserved.
            </p>
          </div>
          <div className="py-4 px-5 bg-primary footer-shape position-relative text-center text-md-end">
            <p className="mb-0">
              {" "}
              <Link className="text-dark" to="/Privacy">
                Privacy Policy
              </Link>{" "}
              <Link className="text-dark ms-4" to="/Terms">
                Terms
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
};

export default Footer;
