import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header">
        <h1 className="display-3 text-uppercase text-white mb-3">
          Custom Clearance
        </h1>
        <div className="d-inline-flex text-white">
          <h6 className="text-uppercase m-0">
            <Link to="/Home">Home</Link>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase text-white m-0">Custom Clearance</h6>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* Custom Clearance Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 rounded mb-5"
                src="img/Custom Clearance.jpg"
                alt=""
              />
              <h1 className="text-uppercase mb-4">Custom Clearance</h1>
              <p>
                In today's dynamic landscape of cargo clearance services, there
                is a growing reliance on electronic data exchange for
                streamlined operations. This shift encompasses various facets,
                such as the acceptance of shipping documents in digital formats,
                the seamless transfer of bills of entry and shipping bills, and
                the adoption of customs Electronic Data Interchange (EDI) within
                cargo complexes and customs houses. At our core, we are
                well-equipped to facilitate a multitude of tasks related to
                project imports and exports, ensuring efficient processes
                throughout.
                <p>
                  Our expertise extends to navigating the complexities of
                  valuation procedures, the meticulous submission and
                  cancellation of bonds, the initiation and pursuit of refund
                  claims, and the establishment of productive interactions with
                  pertinent institutions. Import customs clearance involves a
                  multifaceted approach, including activities such as Harmonized
                  System Nomenclature (HSN) classification, duty rate
                  determination, bill of entry creation, custom brokerage
                  management, comprehensive assessment and examination
                  procedures, meticulous shipment planning, and the acquisition
                  of customs exemptions where applicable.
                </p>
                In essence, our comprehensive range of services encompasses the
                entire spectrum of import customs clearance, bolstered by our
                proficiency in electronic data exchange and our commitment to
                ensuring a seamless and efficient process for our clients.
              </p>
            </div>
            {/* Custom Clearance End */}
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Ocean">
                  <i className="fa fa-angle-right me-2" />
                  Ocean Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Air">
                  <i className="fa fa-angle-right me-2" />
                  Air Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Ground">
                  <i className="fa fa-angle-right me-2" />
                  Ground Freight
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Project">
                  <i className="fa fa-angle-right me-2" />
                  Project Cargo
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Value Added Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Break">
                  <i className="fa fa-angle-right me-2" />
                  Break Bulk
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Hazardous">
                  <i className="fa fa-angle-right me-2" />
                  Hazardous Goods
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Cargo">
                  <i className="fa fa-angle-right me-2" />
                  Cargo Insurance
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Custom">
                  <i className="fa fa-angle-right me-2" />
                  Custom Clearance
                </Link>
              </div>
            </div>
            {/* Category End */}
            {/* Category Start */}
            <div className="mb-5">
              <h3 className="text-uppercase mb-4">Trading Services</h3>
              <div className="d-flex flex-column justify-content-start bg-light p-4">
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Fashion
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Food
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Furniture
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Oil and Gas
                </Link>
                <Link className="h6 text-uppercase mb-4" to="/Trading">
                  <i className="fa fa-angle-right me-2" />
                  Retail
                </Link>
              </div>
            </div>
            {/* Category End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
