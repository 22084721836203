import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header">
        <h1 className="display-3 text-uppercase text-white mb-3">
        Services
        </h1>
        <div className="d-inline-flex text-white">
          <h6 className="text-uppercase m-0">
            <Link to="/Home">Home</Link>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase text-white m-0">Services</h6>
        </div>
      </div>
            {/* Services Start */}
            <div className="container-fluid bg-light py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
          <h1 className="display-5 text-uppercase mb-4">
            We Provide <span className="text-primary">The Best</span> Freight
            Forwarding Services
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Ocean Freight.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Ocean Freight</h4>
                <p>
                  {companyname} is dedicated to delivering unparalleled
                  flexibility in sea freight shipments, setting the standard
                  for..
                </p>
                <Link className="btn text-primary" to="/Ocean">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Air Freight</h4>
                <p>
                  {companyname} stands as your trusted partner when it comes to
                  time-critical air freight services, ensuring prompt
                  deliveries..
                </p>
                <Link className="btn text-primary" to="/Air">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Ground Freight.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Ground Freight</h4>
                <p>
                  The demand for transportation services is a dynamic and
                  region-specific aspect that varies across industries...
                </p>
                <Link className="btn text-primary" to="/Ground">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
              <img className="img-fluid" src="img/Project Cargo.jpg" alt="" />
              <div className="service-icon bg-white"></div>
              <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Project Cargo</h4>
                <p>
                  When it comes to overseeing the logistics of your industrial
                  ventures, be it the construction of a manufacturing plant...
                </p>
                <Link className="btn text-primary" to="/Project">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}
            {/* Portfolio Start */}
            <div className="container-fluid bg-light py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
          <h1 className="display-5 text-uppercase mb-4">
            Explore Our <span className="text-primary">Trading</span> Services
          </h1>
        </div>
        <div className="row gx-5">
          <div className="col-12 text-center"></div>
        </div>
        <div className="row g-5 portfolio-container">
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img className="img-fluid w-100" src="img/Fashion.jpg" alt="" />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Fashion</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img className="img-fluid w-100" src="img/Food.jpg" alt="" />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Food</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img className="img-fluid w-100" src="img/Furniture.jpg" alt="" />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Furniture</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-2 portfolio-item first"></div>
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img
                className="img-fluid w-100"
                src="img/Oil and Gas.jpg"
                alt=""
              />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Oil and Gas</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
            <div className="position-relative portfolio-box">
              <img className="img-fluid w-100" src="img/Retail.jpg" alt="" />
              <Link className="portfolio-title shadow-sm" to="/Trading">
                <p className="h4 text-uppercase">Retail</p>
                <span className="text-body">Trading Services</span>
              </Link>
              <Link className="portfolio-btn" to="/Trading">
                <i className="bi bi-plus text-white" />
              </Link>
            </div>
          </div>
          <div className="col-xl-2 portfolio-item first"></div>
        </div>
      </div>
      {/* Portfolio End */}

      {/* Blog Start */}
      <div className="container-fluid py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
          <h1 className="display-5 text-uppercase mb-4">
            Some Of Our <span className="text-primary">Value Added</span>{" "}
            Services
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <div className="bg-light">
              <img className="img-fluid" src="img/Break Bulk.jpg" alt="" />
              <div className="p-4">
                <div className="d-flex justify-content-between mb-4"></div>
                <h4 className="text-uppercase mb-3">Break Bulk</h4>
                <Link className="text-uppercase fw-bold" to="/Break">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="bg-light">
              <img className="img-fluid" src="img/Hazardous Goods.jpg" alt="" />
              <div className="p-4">
                <div className="d-flex justify-content-between mb-4"></div>
                <h4 className="text-uppercase mb-3">Hazardous Goods</h4>
                <Link className="text-uppercase fw-bold" to="/Hazardous">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="bg-light">
              <img className="img-fluid" src="img/Cargo Insurance.jpg" alt="" />
              <div className="p-4">
                <div className="d-flex justify-content-between mb-4"></div>
                <h4 className="text-uppercase mb-3">Cargo Insurance</h4>
                <Link className="text-uppercase fw-bold" to="/Cargo">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="bg-light">
              <img
                className="img-fluid"
                src="img/Custom Clearance.jpg"
                alt=""
              />
              <div className="p-4">
                <div className="d-flex justify-content-between mb-4"></div>
                <h4 className="text-uppercase mb-3">Custom Clearance</h4>
                <Link className="text-uppercase fw-bold" to="/Custom">
                  Read More <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog End */}
      <Footer />
    </>
  );
}
