import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header">
        <h1 className="display-3 text-uppercase text-white mb-3">
          Trading Services
        </h1>
        <div className="d-inline-flex text-white">
          <h6 className="text-uppercase m-0">
            <Link to="/Home">Home</Link>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase text-white m-0"> Trading Services</h6>
        </div>
      </div>
      {/* About Start */}
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-6">
            <h1 className="display-5 text-uppercase mb-4">Fashion</h1>

            <p>
              The fashion industry operates in a whirlwind of rapidly evolving
              trends and ever-shortening product life cycles. We understand the
              pivotal role of keeping pace with this dynamic rhythm. Success in
              fashion hinges on speed, quality, flexibility, and the ability to
              adapt seamlessly to change. Our approach to fashion logistics
              isn't just an adjustment – it's a complete transformation. We've
              realigned our resources to cater specifically to the fashion
              sector. From sourcing to distribution, our operations are finely
              tuned to match the dynamic nature of fashion. Agility isn't merely
              our response; it's a proactive strategy to ensure our clients stay
              ahead of the curve.
            </p>
          </div>
          <div className="col-lg-6 pb-5" style={{ minHeight: "400px" }}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/Fashion.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-6 pb-5" style={{ minHeight: "400px" }}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/Food.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>{" "}
          <div className="col-lg-6">
            <h1 className="display-5 text-uppercase mb-4">Food</h1>

            <p>
              The food industry operates in a world where product life cycles
              shrink year after year. We recognize this reality, where trends
              change swiftly, and consumer preferences shift rapidly. In
              response, we've not only stayed attuned but also revolutionized
              our approach to meet industry demands. Speed, quality,
              flexibility, and adaptability underscore our commitment to food
              logistics. Our unwavering dedication to the food sector led us to
              revamp our resources entirely. Success in this industry requires
              anticipating market trends, not just reacting to them. By aligning
              our operations with the industry's pace, we've developed an
              operational model built on agility. We're no longer just logistics
              providers; we've become an integral link in your supply chain,
              enabling you to match the relentless cadence of the food world.
            </p>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* About Start */}
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-6">
            <h1 className="display-5 text-uppercase mb-4">Furniture</h1>

            <p>
              Furniture manufacturing, importing, and retailing encompass
              diverse sectors, each with its distinct characteristics.
              Nevertheless, a common thread among them is the presence of
              logistical challenges. The industry grapples with transportation
              complexities that disrupt the smooth flow of operations, from
              production delays to delivery schedule interruptions. Even the
              most efficient companies encounter hurdles, and global reach
              capabilities often fall short for furniture businesses, regardless
              of their scale. At {companyname} Enterprises, we confront these
              challenges as part of our daily routine, especially in the
              furniture market. We've accumulated three decades of combined
              experience addressing the industry's unique demands. Over the
              years, our steadfast commitment to delivering positive outcomes
              has earned us a formidable reputation in the furniture sector.
            </p>
          </div>
          <div className="col-lg-6 pb-5" style={{ minHeight: "400px" }}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/Furniture.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-6 pb-5" style={{ minHeight: "400px" }}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/Oil and Gas.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>{" "}
          <div className="col-lg-6">
            <h1 className="display-5 text-uppercase mb-4">Oil and Gas</h1>

            <p>
              Within the specialized chemicals domain, our dedicated team
              possesses extensive expertise in handling chemical products. They
              undergo continuous training in dangerous goods and transport
              regulations to ensure a secure and hazard-free supply chain for
              your company. Managing hazardous materials demands an unparalleled
              level of precision and caution. Our specialized chemicals team
              excels in transporting dangerous goods, whether volatile
              substances or materials with specific handling requirements. We
              adhere to the highest safety standards at every step of the
              transportation process.
            </p>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* About Start */}
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-6">
            <h1 className="display-5 text-uppercase mb-4">Retail</h1>

            <p>
              Our approach to retail logistics is built on collaboration at its
              core. We establish close partnerships with your vendors, actively
              monitoring production timelines to ensure timely availability. Our
              focus is on enabling a swift and seamless turnover, commencing at
              the manufacturer's doorstep and concluding at the port. Working
              hand in hand with our clients, we foster continuous value creation
              by implementing and overseeing key performance indicators. We
              place our trust in data-driven insights to fine-tune operations.
              Regular customer business reviews provide the opportunity to gauge
              progress and make strategic adjustments as necessary.
            </p>
          </div>
          <div className="col-lg-6 pb-5" style={{ minHeight: "400px" }}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/Retail.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
